import React, { useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"
import ReactMarkdown from "react-markdown"
import remarkGfm from 'remark-gfm';

import Image from "../components/image"

import "./partner.scss"

import Arrow from "../images/arrow.svg"
import Download from "../images/icon-download.svg"

const Partner = ({ pageContext }) => {
  const { partner } = pageContext
  const backLinkRef = useRef(null)
  const image = {
    fileName: "partner_mockup.png",
    alt: "Partner",
  }
  useEffect(() => {
    if (backLinkRef && backLinkRef.current) {
      backLinkRef.current.click()
    }
  }, [])
  return (
    <ModalRoutingContext.Consumer>
      {({ modal }) =>
        modal ? (
          <div className="partner-modal-outer">
            <div className="partner-content">
              <div className="partner-mockup">
                {partner && partner.logo && partner.logo.url ? (
                  <div
                    style={{
                      backgroundImage: `url(https://villagemedia.hu/api${partner.logo.url})`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      minHeight: "100vh",
                    }}
                  />
                ) : (
                  <Image {...image} />
                )}
              </div>
              <div className="partner-content-text">
                <div className="back-navigation">
                  <span role="button" onClick={() => navigate(-1)}>
                    <Arrow />
                    <div>Vissza</div>
                  </span>
                </div>
                <div>
                  <h4>{partner.sector.title}</h4>
                  <h3>{partner.name}</h3>
                  <ReactMarkdown children={partner.Detailed || ""} remarkPlugins={[remarkGfm]} />
                </div>
                <div className="partner-info">
                  {partner.targetGroup && (
                    <div className="target-group">
                      <div className="target-group-title">célcsoport</div>
                      <div className="target-group-content">
                      {partner.targetGroup}
                      </div>
                    </div>
                  )}
                  {partner.usersReached && (
                    <div className="users-reached">
                      <div className="users-reached-title">elérés</div>
                      <div className="users-reached-content">
                        {partner.usersReached}
                      </div>
                    </div>
                  )}
                  {partner.listprice && (
                    <div className="listprice">
                      <div className="listprice-title">Listaár</div>
                      <div className="listprice-content">
                      <ReactMarkdown children={partner.listprice} remarkPlugins={[remarkGfm]} />
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className="pdf-download">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://villagemedia.hu/api/uploads/Village_Media_osszefuzott_852be3a885.pdf"
                  >
                    Árlista
                    <Download />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        ) : (
          <Link ref={backLinkRef} to="/portfolio" />
        )
      }
    </ModalRoutingContext.Consumer>
  )
}

export default Partner
